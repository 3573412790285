import React, { useEffect, useContext, useState, Fragment } from "react";
import { sendRequest } from "../../../API";
import { SkeletonLoader } from "../../../Component/Spinners/Skeleton";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.css";
import { GlobalContext } from "../../../Context";
import { FeatureSubContext } from "../FeatureContext";
import "bootstrap/dist/css/bootstrap.min.css";
import { DefaultItemSelect, SuccessIcon } from "./CategoryUtils";
import CategoryNoData from "./CategoryNoData";
import CategoryTable from "./CategoryTable";

const Category = () => {
  const {
    openViewItemsModal,
    setModalUtils,
    modalUtils
  } = useContext(GlobalContext);
  const {
    data,
    setViewCategory,
    categoryUpdate,
    setCategoryUpdate,
    getAllCategoryItem,
    firstCategory,
    setFirstCategory
  } = useContext(FeatureSubContext);
  const [ visibleDefault, setVisibleDefault ] = useState([])
  const [ visibleDelete, setVisibleDelete ] = useState([])
  const [ visibleAuto, setVisibleAuto ] = useState([])
  const [ visibleToggle, setVisibleToggle ] = useState([])

  document.title = "REQO | Manage Category";

  const handleSetCount = (category) => {
    setVisibleDefault(prev => [...prev, category.id])
    let count = 0
    let isDefaultItem
    let update = false
    const result = {
      CompanyID: category.CompanyID,
      id: category.id,
    }
    const { isDefaultItemSelect } = category
    data.forEach((category) => {
      if (category.isDefaultItemSelect === "true") count += 1
    })

    if (count <= 3 && isDefaultItemSelect === 'true') {
      isDefaultItem = isDefaultItemSelect === 'true' ? 'false' : 'true'
      update = true
      result['isDefaultItemSelect'] = isDefaultItem
    } else if (count < 3) {
      isDefaultItem = isDefaultItemSelect === 'true' ? 'false' : 'true'
      result['isDefaultItemSelect'] = isDefaultItem
    } else {
      toast.error('Only Three Category Can Set Default Items!')
      return
    }

    sendRequest('PUT', "categoryItems/updateCategoryDefaultItem", result)
      .then(() => {
        toast.success(`category ${update ? 'can not' : 'can'} set default items now!`);
        getAllCategoryItem();
        const filterId = visibleDefault.filter((ids) => ids !== category.id)
        setVisibleDefault(filterId)
      })
      .catch(() => {
        toast.error('something went wrong, please refresh the page')
      })
  }

  const handleDelete = async (id, CompanyID) => {
    try {
      sendRequest("DELETE", "categoryItems/deleteCategoryItem", {
        id,
        CompanyID,
      })
        .then(() => {
          toast.success("category deleted!");
          const result = [...visibleDelete]
          if(result.includes(id)){
            result.splice(result.indexOf(id),1)
            setVisibleDelete(result)
          }
          getAllCategoryItem();
        })
        .catch(() => {
          toast.error("try again!");
        });
    } catch (err) {
      toast.error("Can't delete");
    }
  };

  const updateCategoryItem = async (data, isDelete, ids) => {
    try {
      sendRequest("PUT", "categoryItems/updateCategoryItem", [data])
        .then(() => {
          if (isDelete) {
            toast.success("category Item is deleted!");
          }
          const filterId = visibleAuto.filter(id => id !== ids)
          setVisibleAuto(filterId)
          getAllCategoryItem();
        })
        .catch(() => {
          toast.error("try again!");
        });
    } catch (err) {
      toast.error("Can't delete");
    }
  };

  const handleToggle = async (
    id,
    CompanyID,
    check,
    CategoryName,
    CategoryItems,
    defaultItems,
    categoryAdmin,
    selectedAdmins
  ) => {
    let checked = check === "false" ? "true" : "false";
    const data = [{
      id,
      CompanyID,
      checked,
      CategoryName,
      CategoryItems,
      defaultItems,
      selectedAdmins,
      categoryAdmin
    }];
    try {
      await sendRequest("PUT", "categoryItems/updateCategoryItem", data);
      const resultToggle = [...visibleToggle]
      if(resultToggle.includes(id)){
        resultToggle.splice(resultToggle.indexOf(id),1)
      }
      setVisibleToggle(resultToggle)
      await getAllCategoryItem();
    } catch (err) {
      toast.error("Failed To Update Toggle");
    }
  };

  const handleView = (category) => {
    setViewCategory(category);
    setCategoryUpdate(!categoryUpdate);
    setModalUtils({ addCategory: true });
  };

  useEffect(() => {
    getAllCategoryItem();
  }, []);

  return (
    <section className="category">
      {data.length !== 0 ? (
        <div
          className={`view--items employee--section ${(openViewItemsModal || modalUtils.createRequestModal) ? "opacity--reduce" : null
            }`}
        >
            {
              (firstCategory === 'created first category & Items')&& (
                data[0].isDeleted !== undefined ?(
                  <div className="category-popup">
                  <SuccessIcon className ='success-icon'/>
                  <p className="popup-message" >Find all the Categories that have been created here. You can also create default items ( {<DefaultItemSelect/>} ) that will enable to auto - request for an item. So every time a new team member joins, a request will be created with those items by default!</p>
                  <p className="popup-button" onClick={() => setFirstCategory('')}>Okay</p>
                </div>
                ):(
                  <SkeletonLoader/>
                )
              )
            }

          <div className='category-total'>
            <div className="category-total-count">
              <p>Acitve Category( {data.length === 1 && data[0].isDeleted !== "false"? 0: data.length} )</p>
            </div>
            <div className="category-add">
              <p onClick={() => {setModalUtils({ addCategory: true })}}>+ Add Category</p>
            </div>
          </div>

          <div className="category-table-view">
            <table className="category-item-table">
              <thead className="category-item-head">
                <tr>
                  <th></th>
                  <th>Category</th>
                  <th>items</th>
                  <th>Active/InActive</th>
                  <th>Category Admin</th>
                  <th>Created Date</th>
                  <th>Default Item</th>
                </tr>
              </thead>
              <thead>
              <tr className='request--line'><td></td></tr>

              </thead>
              <tbody className="category-item-body">
              {data.map((Category, id) => {
                  return (
                    <Fragment key={id}>
                      <CategoryTable
                        Category={Category}
                        handleDelete={handleDelete}
                        handleToggle={handleToggle}
                        handleView={handleView}
                        handleSetCount={handleSetCount}
                        updateCategoryItem={updateCategoryItem}
                        visibleDefault = {visibleDefault}
                        visibleAuto = {visibleAuto}
                        setVisibleAuto = {setVisibleAuto}
                        visibleDelete = {visibleDelete}
                        setVisibleDelete = { setVisibleDelete}
                        visibleToggle = {visibleToggle}
                        setVisibleToggle = {setVisibleToggle}
                      />
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="catNo">
          <CategoryNoData/>
        </div>
      )}
    </section>
  );
};

export default Category;
