import React, { useEffect, useState } from 'react'
import { FiTrash2 } from 'react-icons/fi'
import { getRequest, sendRequest } from '../../../API'
import moment from "moment";
import { toast } from 'react-toastify';
import { useContext } from 'react';
import { GlobalContext } from '../../../Context';
import Loader from '../../../Component/Spinners/Loader';



const Comments = ({ emailID, ticketNo }) => {
  const { user } = useContext(GlobalContext);
  let [comment_data, setCommentData] = useState([]);
  const [value, setValue] = useState("");
  const { empName } = (user && user.data) || {};
  const [loader, setLoader] = useState({
    add: false, delete: false, index: null
  });


  const fetch_comments = () => {
    const uri = `query/getcomments?emailId=${emailID}&ticketNo=${ticketNo}`;
    getRequest(uri).then((res) => {
      const { result: { Item } } = res.data;
      if (Item.comments && Item.comments.length > 0) {
        const { comments } = Item;
        setCommentData(comments);
      } else setCommentData([]);
    }).catch(() => {
      setCommentData([]);
    });
  }
  useEffect(() => {
    fetch_comments();
  }, []);



  const addComment = () => {
    if (!value.trim()) return null;
    setLoader({ ...loader, add: true });
    const uri = `query/addcomments?emailId=${emailID}&ticketNo=${ticketNo}`;
    const comment_data = [{
      comment: value,
      name: empName,
      time: new Date().toISOString()
    }];
    sendRequest("POST", uri, { comment_data }).then(() => {
      setLoader({ ...loader, add: false });
      fetch_comments();
      setValue("");
      toast.success("comment added!");
    }).catch(() => {
      setLoader({ ...loader, add: false });
      toast.error("something went wrong!");
    })
  }

  const delete_comment = (index) => {
    setLoader({ ...loader, delete: true, index });
    const uri = `query/deletecomments?emailId=${emailID}&ticketNo=${ticketNo}&index=${index}`;
    getRequest(uri).then(() => {
      setLoader({ ...loader, delete: false });
      fetch_comments();
    }).catch((err) => {
      setLoader({ ...loader, delete: false });
      const { status, data } = err.response || {};
      if (status === 403) return toast.error(data.message);
      toast.error("something went wrong!");
    })
  }




  return <main>
    <div className='request-comment'>
      <p className='comment-heading'>Comments</p>
      {
        comment_data && comment_data.map((items, i) => {
          const { name, comment, time } = items;
          return <section className='comments' key={i}>
            <div className='comment--logo'>
              <p>{name && name.substring(0, 1)}</p>
            </div>
            <div className='comment--content'>
              <article className='name'>{name}
                {(name === empName) ? <span onClick={() => delete_comment(i)} className='remove--comment'>
                  {loader.delete && (loader.index === i) ? <Loader /> : <FiTrash2 />}</span> : null}
              </article>
              <p className='time'>{moment(time).format("DD MMM [at] h:mm A")}</p>
              <p className='description'>
                {comment}
              </p>
            </div>
          </section>
        }).reverse()
      }
      <textarea value={value} placeholder='Leave a comment' onChange={(e) => setValue(e.target.value)}
        className='comment-textarea'></textarea>
      <button className='btn' onClick={() => addComment()}>
        {loader.add ? <Loader size={22} /> : "Add Comment"}
      </button>
    </div>

  </main>
}

export default Comments;