import React from 'react'

export const ApprovedSvg = () => {
    return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.8341 5.26278C11.781 5.20922 11.7178 5.16671 11.6482 5.1377C11.5785 5.10869 11.5038 5.09375 11.4284 5.09375C11.353 5.09375 11.2783 5.10869 11.2086 5.1377C11.139 5.16671 11.0758 5.20922 11.0227 5.26278L6.76555 9.52563L4.97697 7.73135C4.92182 7.67807 4.85671 7.63618 4.78536 7.60806C4.71402 7.57994 4.63783 7.56615 4.56116 7.56748C4.48448 7.56881 4.40882 7.58522 4.33849 7.61579C4.26816 7.64636 4.20454 7.69048 4.15126 7.74564C4.09798 7.80079 4.05609 7.8659 4.02797 7.93725C3.99985 8.00859 3.98607 8.08478 3.98739 8.16145C3.98872 8.23813 4.00513 8.31379 4.0357 8.38412C4.06627 8.45445 4.11039 8.51807 4.16555 8.57135L6.35983 10.7656C6.41295 10.8192 6.47615 10.8617 6.54579 10.8907C6.61542 10.9197 6.69011 10.9347 6.76555 10.9347C6.84098 10.9347 6.91567 10.9197 6.9853 10.8907C7.05494 10.8617 7.11814 10.8192 7.17126 10.7656L11.8341 6.10278C11.8921 6.04927 11.9384 5.98432 11.9701 5.91204C12.0017 5.83975 12.0181 5.76169 12.0181 5.68278C12.0181 5.60386 12.0017 5.5258 11.9701 5.45352C11.9384 5.38123 11.8921 5.31629 11.8341 5.26278V5.26278Z" fill="#27AE60" />
        <circle cx="8" cy="8" r="7.5" stroke="#27AE60" />
    </svg>
}

export const InReviewSvg = () => {
    return <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 8.04545C1 8.04545 4.27273 1.5 10 1.5C15.7273 1.5 19 8.04545 19 8.04545C19 8.04545 15.7273 14.5909 10 14.5909C4.27273 14.5909 1 8.04545 1 8.04545Z" stroke="#27AE60" strokeWidth="1.63636" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10 10.5009C11.3556 10.5009 12.4545 9.40195 12.4545 8.04634C12.4545 6.69073 11.3556 5.5918 10 5.5918C8.64439 5.5918 7.54546 6.69073 7.54546 8.04634C7.54546 9.40195 8.64439 10.5009 10 10.5009Z" stroke="#27AE60" strokeWidth="1.63636" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

}

export const RejectedSvg = () => {
    return <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 4L9 9" stroke="#E70000" strokeWidth="1.17647" strokeLinecap="round" />
        <path d="M4 9L9 4" stroke="#E70000" strokeWidth="1.17647" strokeLinecap="round" />
        <circle cx="6.5" cy="6.5" r="6" stroke="#E70000" />
    </svg>
}
export const BackSvg = () => {
    return <svg width="16" height="25" viewBox="0 0 16 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.4">
            <path d="M2 13.1621L14.0999 22.9392" stroke="black" strokeWidth="3" strokeLinecap="round" />
            <path d="M2 13L13 2" stroke="black" strokeWidth="3" strokeLinecap="round" />
        </g>
    </svg>
}

