import React, { useContext } from 'react'
import { useGoogleLogin } from "@react-oauth/google";
import { GoogleLogo, MicrosoftLogo } from "./../../Component/Authenticate/GoogleLogo";
import { Link, useHistory } from 'react-router-dom';
import "../../Component/Style/auth.css";
import SignupSvg from '../../Component/Authenticate/SignupSvg';
import { useState } from 'react';
import { GlobalContext } from '../../Context';
import { sendRequest } from '../../API';
import { toast } from 'react-toastify';
import Loader from '../../Component/Spinners/Loader';
import { errorHandleGoogle, errorHandleMicrosoft, handle_google_token, msalInstance } from './microsoft';
import { CustomToasterAuth } from '../../Component/CustomToaster';



const SignUp = () => {
  const { setCookie, setCustomToast, customToast } = useContext(GlobalContext);
  const [btnTxt, setBtnTxt] = useState("Sign Up with Google");
  const [textMicrosoft, setTxtMicrosoft] = useState("Sign Up With Microsoft");
  const history = useHistory();


  const success_response = (data) => {
    var { email, given_name, picture } = data;
    var getDomain = email.split("@").pop().split(".");
    const personalDomain = ["gmail", "yahoo", "hotmail", "outlook", "mailinator"];
    const getPlDomain = personalDomain.find((items) => items === getDomain[0]);
    if (getPlDomain) return setCustomToast({
      content: `We noticed you have used a personal email ID. 
    Please enter a valid work email ID to get started.`, open: true, error: true
    })

    setBtnTxt("Processing...");
    sendRequest("POST", "auth", { company: getDomain[0], userEmailID: email, given_name })
      .then((res) => {
        const { message, token, result } = res.data || {};

        if (message === 'new user found!') {
          const totalCount = result.companyData.actualEmployeeCount
          const companyID = result.companyData.companyID
          return history.push({ pathname: "/userworkspace", state: { getDomain, given_name, email, totalCount, companyID, picture } })
        }

        if (result && (result.isActive !== "true")) {
          setBtnTxt("Sign In With Google");
          return toast.info("Account Inactive!");
        }
        if (message && message.includes("user")) {
          setCookie("u_detials", token, { path: "/" });
          setCookie("g_token", picture, { path: "/" });
          return window.location.href = "/dashboard";
        }
        return history.push({ pathname: "/createworkspace", state: { getDomain, given_name, email, picture } });
      }).catch((e) => {
        setBtnTxt("Sign Up With Google");
        if (e.response && e.response.status === 401) return toast.error("account deleted!");
        if (e.response && e.response.status === 403) return toast.error("Subscription expired!, contact admin");
        toast.error("Something went wrong!");
      })
  };


  const authMicrosoftHandler = async () => {
    try {
      setTxtMicrosoft("Processing...");
      var loginRequest = {
        scopes: ["user.read", "mail.send"]
      };
      let data = await msalInstance.loginPopup(loginRequest);
      let { username, name } = (data && data.account) || {};
      var getDomain = username && username.split("@").pop().split(".");
      const personalDomain = ["gmail", "yahoo", "hotmail", "outlook", "mailinator"];
      const getPlDomain = personalDomain.find((items) => items === getDomain[0]);
      if (getPlDomain) {
        setTxtMicrosoft("Sign Up With Microsoft");
        return setCustomToast({
          content: `We noticed you have used a personal email ID. 
        Please enter a valid work email ID to get started.`, open: true, error: true
        })
      }

      sendRequest("POST", "auth", { company: getDomain[0], userEmailID: username, givenName: name })
        .then((res) => {
          const { message, token, result } = res.data || {};

          if (message === 'new user found!') {
            const totalCount = result.companyData.actualEmployeeCount
            const companyID = result.companyData.companyID
            return history.push({ pathname: "/userworkspace", state: { getDomain, givenName: name, email: username, totalCount, companyID, tokenId: data.idToken } })
          }

          if (result && (result.isActive !== "true")) {
            setTxtMicrosoft("Sign In With Microsoft");
            return toast.info("Account Inactive!");
          }
          if (message && message.includes("user")) {
            setCookie("g_token", data.idToken, { path: "/" });
            setCookie("u_detials", token, { path: "/" });
            return window.location.href = "/dashboard";
          }
          return history.push({
            pathname: "/createworkspace", state: {
              getDomain, givenName: name, email: username, tokenId: data.idToken
            }
          });
        }).catch((e) => {
          setTxtMicrosoft("Sign In With Microsoft");
          if (e.response && e.response.status === 401) return toast.error("account deleted!");
          if (e.response && e.response.status === 403) return toast.error("Subscription expired!, contact admin");
          toast.error("Something went wrong!");
        })
    } catch (err) {
      setTxtMicrosoft("Sign In with Microsoft");
      toast.error(errorHandleMicrosoft(err));
    }
  };


  const login = useGoogleLogin({
    onSuccess: async tokenResponse => {
      const result = await handle_google_token(tokenResponse);
      success_response(result.data);
    },
    onNonOAuthError: error => toast.error(errorHandleGoogle(error.type)),
    onError: error => toast.error(errorHandleGoogle(error))
  });

  return <main className='register'>
    {customToast.open ? <CustomToasterAuth /> : null}
    <div className='left--section animate__animated animate__fadeInLeft'>
      <article className='lt--section--text'>
        <h1>Lets get started</h1>
        <p>Sign up with your work email address to create an account.</p>

        <div className='social--providers'>
          <section className='google--signup animate__animated animate__zoomIn'>
            <button disabled={(btnTxt.startsWith("P") || textMicrosoft.startsWith("P")) ? true : false}
              onClick={() => login()} className="btn signup--btn"> <GoogleLogo /> &nbsp;
              <span>{btnTxt} {btnTxt.startsWith("P") ? <Loader borderColor="#3263F3" borderTopColor="whitesmoke" /> : null}</span>
            </button>
          </section>

          <section className='google--signup microsoft--signup animate__animated animate__zoomIn'>
            <button disabled={(btnTxt.startsWith("P") || textMicrosoft.startsWith("P")) ? true : false}
              className="btn signup--btn" onClick={() => {
                sessionStorage.clear();
                authMicrosoftHandler();
              }}> <MicrosoftLogo /> &nbsp;
              <span>{textMicrosoft} {textMicrosoft.startsWith("P")
                ? <Loader borderColor="#3263F3" borderTopColor="whitesmoke" /> : null}
              </span>
            </button>
          </section>

        </div>

        <div className='login--link'>Already have an acoount? <Link to="/login"><span>Sign In</span></Link></div>
      </article>
    </div>
    <div className='right--section'>
      <article>
        <SignupSvg />
        <h1 className='animate__animated animate__fadeInUp'>Request and Relax</h1>
        <p className='animate__animated animate__fadeInUp'>
          Enjoy hassle free,  seemless asset management with easy monitoring of requests within a few clicks.
        </p>
      </article>
    </div>
  </main>
}

export default SignUp;