import React, { createContext, useEffect } from "react";
import { useState } from "react";
import { useCookies } from "react-cookie";
import jwt_decode from "jwt-decode";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { getRequest } from "./API";
const GlobalContext = createContext();


const Context = ({ children }) => {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [viewItem, setViewItem] = useState();
  const [user, setUser] = useState();
  const [companyData, setCompanyData] = useState({});
  const [cookie, setCookie, removeCookie] = useCookies();
  const [openViewItemsModal, setOpenViewItemsModal] = useState(false);
  const [closeModal, setCloseModal] = useState({
    createRequest: false,
    sentRequest: false,
    modifyBottom: 0
  });
  const [modalUtils, setModalUtils] = useState({
    approveRejectModal: false,
    createRequestModal: false,
    openSentRequestModal: false,
    addEmployee: false,
    addCategory: false,
    openInvoices: false,
    cancelBilling: false,
    costBreakdown: false
  });
  const [globalError, setGlobalError] = useState({});
  const [handleProfile, setHandleProfile] = useState({ open: false, items: "" });
  const [customToast, setCustomToast] = useState({ content: "", error: false, open: false })
  const [openMyRequestTab, setOpenMyRequestTab] = useState(false);
  const [deleteProfileModal, setDeleteProfileModal] = useState({ open: false, data: null });


  
  useEffect(() => {
    window.scrollTo(0, 0);
    if (cookie && cookie.u_detials) {
      try {
        const getDetails = jwt_decode(cookie.u_detials);
        const getGmailDetails = cookie.g_token;

        // normal redirection...
        const { empRole } = getDetails && getDetails.data;
        if (empRole && empRole.includes("requester")) setOpenMyRequestTab(true);
        setUser({ ...getDetails, profile_img: getGmailDetails });

        // // requester redirect...
        // const data = { ...getDetails.data, empID: "Ant-099", emailID: "tomwood@antstack.io", empRole: "requester", empName: "Tom Wood", responderID: "" }
        // if (data.empRole && data.empRole.includes("requester")) setModalUtils({ openMyRequestTab: true })
        //  const company = { ...getDetails.company };
        //  setUser({ data, company, profile_img: getGmailDetails.picture });

        // // responder redirect...
        // const data = { ...getDetails.data, empID: "Ant-002", emailID: "shravan@antstack.io", empRole: "responder", empName: "Shravan", responderID: "" }
        // const company = { ...getDetails.company };
        // setUser({ data, company, profile_img: getGmailDetails.picture });

        // // admin redirect...
        // const data = { ...getDetails.data, empID: "Ant-006", emailID: "divya.poonthottam@antstack.io", empRole: "admin", empName: "Divya", responderID: "" }
        // const company = { ...getDetails.company };
        // setUser({ data, company, profile_img: getGmailDetails.picture });

        // // super admin redirect...
        // const data = { ...getDetails.data, empID: "Ant-001", emailID: "shravan@antstack.io", empRole: "super_admin", empName: "Super Admin", responderID: "" }
        // const company = { ...getDetails.company };
        // setUser({ data, company, profile_img: getGmailDetails.picture });
      } catch (error) {
        removeCookie("u_detials");
        window.location.href = "/login"
      }
    }
  }, [cookie, pathname]);


  const logout = () => {
    // msalInstance.logoutRedirect();
    sessionStorage.clear();
    removeCookie("u_detials", { path: "/" });
    removeCookie("g_token", { path: "/" });
    setUser({});
    window.location.href = "/login"
  }

  const checkForSubscription = () => {
    return new Promise((res, rej) => {
      const { subscriptionType, subscriptionEnded } = (user && user.company) || {};
      if (subscriptionType === "EXPIRED") return rej(403);
      let checkForToday = moment(subscriptionEnded).isSame(moment(), 'day');
      let checkBeyond = moment(subscriptionEnded).isBefore(moment(), 'day');
      if (checkForToday || checkBeyond) return rej(403);
      return res(200);
    });
  };

  const getCompanyData = async () => {
    return await new Promise((resolve, rej) => {
      const { companyID, companyName } = (user && user.company) || {};
      getRequest(`company/getCompany?companyID=${companyID}&companyName=${companyName}`).then((res) => {
        let { result } = res.data;
        if (result) {
          setCompanyData(result);
          resolve();
        }
      }).catch(() => {
        rej();
        setCompanyData({});
      });
    })
  };

  const getManagerPendingRequest = (emailId) => {
    const { companyID } = (user && user.company) || {};
    return new Promise((res, rej) => {
      getRequest(`query/pendingrequest?companyID=${companyID}&emailId=${emailId}`)
        .then((items) => res(items))
        .catch(() => rej());
    });
  };


  return <GlobalContext.Provider value={{
    viewItem,
    setViewItem,
    closeModal,
    setCloseModal,
    modalUtils,
    setModalUtils,
    openViewItemsModal,
    setOpenViewItemsModal,
    cookie,
    setCookie,
    user,
    setUser,
    open,
    setOpen,
    logout,
    checkForSubscription,
    companyData,
    setCompanyData,
    getCompanyData,
    getManagerPendingRequest,
    globalError,
    setGlobalError,
    handleProfile,
    setHandleProfile,
    customToast,
    setCustomToast,
    openMyRequestTab,
    setOpenMyRequestTab,
    setDeleteProfileModal,
    deleteProfileModal
  }}>
    {children}
  </GlobalContext.Provider >
};

export { GlobalContext, Context };