import React, { createContext, useContext } from "react";
import { useState } from "react";
import { GlobalContext } from "../../Context";
import { getRequest } from "../../API";
import { toast } from "react-toastify";
const FeatureSubContext = createContext();


const FeatureContext = ({ children }) => {
  const { user, setGlobalError } = useContext(GlobalContext);
  const { companyID, emailID, empRole } = (user && user.data) || {};
  const [myRequest, setMyRequest] = useState([{}]);
  const [manageRequest, setManageRequest] = useState([{}]);
  const [employeeData, setEmployeeData] = useState([{}]);
  const [categoryUpdate, setCategoryUpdate] = useState(false)
  const [category, setCategory] = useState([]);
  const [firstCategory, setFirstCategory] = useState('')
  const [viewCategory, setViewCategory] = useState();
  const [data, setData] = useState([{ CategoryItems: [{}] }]);
  const [autoHide, setAutoHide] = useState({ trigger: true, index: 0 });
  const [isApprove, setIsApprove] = useState(false);
  const [super_Admins, setSuper_Admins] = useState([]);
  const [requestView, setRequestView] = useState();



  const getMyRequests = async () => {
    getRequest(`query/getRequest/requester?emailId=${emailID}`).then((res) => {
      let { Items } = res.data && res.data.result;
      if (Items.length > 0) {
        Items = Items.sort((a, b) => new Date(b.requestCreatedTime) - new Date(a.requestCreatedTime))
        Items = Items.filter((items) => items.isDeleted !== true);
        setMyRequest(Items);
      }
      else setMyRequest([])
    }).catch((err) => {
      setGlobalError(err.response);
      setMyRequest([]);
    });
  }

  const getManageRequests = () => {
    let path = empRole.includes("super")
      ? `query/getRequest/superadmin?companyID=${companyID}`
      : `query/getRequest/grantor?emailId=${emailID}`;

    getRequest(path).then((res) => {
      let { Items } = res.data && res.data.result;
      if (Items.length > 0) {
        Items = Items.sort((a, b) => new Date(b.requestCreatedTime) - new Date(a.requestCreatedTime))
        Items = Items.filter((items) => items.emailID !== emailID && items.isDeleted !== true);
        setManageRequest(Items);
      }
      else setManageRequest([])
    }).catch((err) => {
      setGlobalError(err.response);
      setManageRequest([]);
    });
  }

  const getCheckedCatgory = async () => {
    getRequest(`categoryItems/getCategoryItem?CompanyID=${companyID}`)
      .then((res) => {
        const { body } = res.data;
        if (body.length > 0) return setCategory(body);
        else {
          setCategory([{ CategoryName: "No Data" }]);
          toast.info("No category found!");
        }
      }).catch((err) => {
        const { status, data } = err.response || {};
        if (status === 403) toast.error(data.message);
        setCategory([{ CategoryName: "No Data" }]);
      })
  };

  const getEmployees = async () => {
    getRequest(`employee/user/allUsers?companyID=${companyID}`)
      .then((res) => {
        let { Items } = res.data;
        if (Items.length > 0) {
          Items = !(empRole === "super_admin") ? Items.filter((item) => item.emailID !== emailID) : Items;
          setEmployeeData(Items);
        }
      }).catch((err) => {
        setGlobalError(err.response);
        setEmployeeData([])
      })
  };

  const getAllAdminAndSuper = () => {
    getRequest(`employee/user/listSuperAndAdmin?companyID=${companyID}`)
      .then((res) => {
        let { result } = res.data;
        if (result.length > 0) {
          result = !(empRole === "super_admin") ? result.filter((item) => item.emailID !== emailID) : result;
          setSuper_Admins(result)
        }
      }).catch((err) => {
        setGlobalError(err.response);
        setSuper_Admins([])
      })
  }

  const getAllCategoryItem = async () => {
    try {
      const result = await getRequest(
        `categoryItems/getAllCategoryItem?CompanyID=${companyID}`
      );
      setData(result.data.body);
      // return "success";
    } catch (err) {
      // const { status, data } = err.response || {};
      // if (status === 403) return toast.error(data.message);
      setGlobalError(err.response);
    }
  };


  const getCurrentEmployee = async () => {
    return await getRequest(`employee/user/getUser?companyID=${companyID}&emailID=${emailID}`)
  }


  return <FeatureSubContext.Provider value={{
    getEmployees,
    getCheckedCatgory,
    category,
    setCategory,
    setData,
    setViewCategory,
    viewCategory,
    data,
    employeeData,
    getMyRequests,
    myRequest,
    getManageRequests,
    manageRequest,
    getCurrentEmployee,
    categoryUpdate,
    setCategoryUpdate,
    getAllCategoryItem,
    firstCategory,
    setFirstCategory,
    autoHide,
    setAutoHide,
    isApprove,
    setIsApprove,
    super_Admins,
    getAllAdminAndSuper,
    setRequestView,
    requestView
  }}>
    {children}
  </FeatureSubContext.Provider>
};

export { FeatureContext, FeatureSubContext };