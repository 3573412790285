import React, { useContext } from 'react'
import SideBar from '../../Component/Navigation/SideBar';
import { GlobalContext } from '../../Context';
import ConditionalPath from './ConditionalPath';
import CreateRequest from '../../Component/Modals/CreateRequest';
import SentRequestModal from '../../Component/Modals/SentRequestModal';
import AddEmployeeModal from '../../Component/Modals/AddEmployeeModal';
import { FeatureContext } from './FeatureContext';
import AddCategory from '../../Component/Modals/AddCategoryModal';
import CancelModal from './Subscription/CancelModal';
import { CostBreakModal } from './Subscription/CostBreakModal';
import { useState } from 'react';
import Banner from '../../Component/Banner';
import UserProfile from '../../Component/UserProfile';
import { CustomToaster } from '../../Component/CustomToaster';



const Dashboard = () => {
  const [modifyBottom, setModifyBottom] = useState(0);
  const { modalUtils, user, handleProfile, customToast } = useContext(GlobalContext);
  const { empRole } = (user && user.data) || {};
  const [bannerOpen, setBannerOpen] = useState(true);
  document.body.style.overflowX = "hidden";


  return <FeatureContext>
    <main className='main--dashboard' style={{
      height: empRole && empRole.includes("requester") ? "91vh" : "none",
      padding: empRole === 'requester' ? "0px 6%" : "",
      background: empRole === 'requester' ? "#f3f1f1a0" : ""
    }}>

      <Banner bannerOpen={bannerOpen} setBannerOpen={setBannerOpen} />
      {customToast.open ? <CustomToaster /> : null}

      {!(empRole && empRole.includes("requester")) ? <section className='sidebar'
        style={{ width: handleProfile.open ? "30%" : "" }}>
        <SideBar />
      </section> : null}

      <section className='content' style={{
        background: empRole === 'requester' ? "none" : "#",
        marginBottom: `${modifyBottom}%`
      }}>
        {modalUtils.createRequestModal ? <CreateRequest /> : null}
        {modalUtils.openSentRequestModal ? <SentRequestModal /> : null}
        {modalUtils.addEmployee ? <AddEmployeeModal /> : null}
        {modalUtils.addCategory ? <AddCategory modifyBottom={modifyBottom}
          setModifyBottom={setModifyBottom} isOnboarding={false} /> : null}
        {modalUtils.cancelBilling ? <CancelModal /> : null}
        {modalUtils.costBreakdown ? <CostBreakModal /> : null}
        {empRole ? <ConditionalPath /> : null}
      </section>

      {handleProfile.open ? <UserProfile /> : null}

    </main>
  </FeatureContext>
}

export default Dashboard;