import React from 'react'

const Inputs = ({ register, name, errors, placeholder, require, value, regex, className, type, readOnly, onFocus, max, min, maxDate }) => {
  return <input max={maxDate} type={type ? type : "text"} defaultValue={value} className={`${className} form-control ${errors[name] ? 'input--error' : null}`}
    placeholder={placeholder} autoComplete="new-password" readOnly={readOnly} onFocus={onFocus}
    {...register(name, { required: require, pattern: regex, max, min })}
  />
};

const CheckBox = ({ name, register, errors, className }) => {
  return <input className={`${className}  ${errors[name] ? 'input--error' : null}`}
    {...register(name, { required: true })} type="checkbox" name={name} />
};

const SelectBox = ({ name, register, errors, message, className, selectClass, data, dataItemDisplay, dataItemValue, value, onClick, valid, option }) => {

  return <select className={`form-control ${selectClass} ${errors && errors[name] ? className : null}`}
    {...register(name, { required: valid })} onClick={onClick}>
    <option className='first-select' hidden value={value ? value : ""}>{value ? value : message}</option>
    {
      data && data.map((items, i) => {
        return <option key={i} value={items[dataItemValue]}>{items[dataItemDisplay]}</option>
      })
    }
    {!option ? <option hidden={selectClass ? true : false} value="">None</option> : null}
  </select>
};

const DropDown = ({ arr }) => {
  return <div className='dropdown card shadow-sm'>
    {
      arr.map((item, i) => {
        return <p key={i}>{item.value}</p>
      })
    }
  </div>
}

const TextArea = ({ placeholder, name, register, className, errors }) => {
  return <textarea placeholder={placeholder} className={`form-control ${className}`}
    {...register(name)}></textarea>
}

export { Inputs, CheckBox, SelectBox, TextArea, DropDown };