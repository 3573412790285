import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import { IoIosClose } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getRequest } from '../API';
import { GlobalContext } from '../Context';
import { FeatureSubContext } from '../Pages/Manage/FeatureContext';
import { Active} from './DashboardUtils';
import { SkeletonLoad } from "./Spinners/Skeleton";


const UserProfile = () => {
  const { setHandleProfile, handleProfile, user, setGlobalError, deleteProfileModal } = useContext(GlobalContext);
  const { getCurrentEmployee } = useContext(FeatureSubContext);
  const userData = (user && user.data) || {}
  const { empName, empRole, empID, emailID, doj, empAddress, responderID, empContact, isActive } = handleProfile.items || {};
  const history = useHistory();
  const [myRequest, setMyRequest] = useState({ Items: [], loader: true });
  const { Items, loader } = myRequest;




  const getEmployee = () => {
    getCurrentEmployee().then((res) => {
      const { result: { Item } } = res.data;
      if (Item) setHandleProfile({ ...handleProfile, items: Item });
    }).catch((err) => {
      const { status, data } = err.response || {};
      if (status === 403) return toast.error(data.message);
    });
  }
  const getMyRequests = (mailID) => {
    setMyRequest({ loader: true, Items: [] });
    getRequest(`query/getRequest/requester?emailId=${mailID}`).then((res) => {
      let { Items } = res.data && res.data.result;
      if (Items.length > 0) {
        Items = Items.filter((items) => items.isDeleted !== true);
      }
      setMyRequest({ Items: Items, loader: false });
    }).catch((err) => {
      setMyRequest({ loader: false, Items: [] });
      setGlobalError(err.response);
    });
  }
  useEffect(() => {
    getMyRequests(emailID);
    if (emailID === userData.emailID) getEmployee();
  }, [emailID]);
  const getPendingData = Items && Items.filter((item) => item.status && item.status.includes("pending"));


  return <section className={`new--profile animate__animated animate__fadeIn `}>
    <article className={`${deleteProfileModal.open && "opacity--reduce"}`}>
      <p className='close--profile'><IoIosClose onClick={() => setHandleProfile({ items: null, open: false })} /></p>
      <div>
        <section className='profile--heading'>
          <article className='profile--logo'>{empName && empName.substring(0, 2)}</article>
          <article className='profile--info'>
            <p>{empName && empName.split(" ")[0].substring(0, 25)} <span>{empRole}</span></p>
            <p></p>
            {((empRole !== "super_admin") || (userData && userData.empRole === "super_admin"))
              ? <p onClick={() => {
                setHandleProfile({ ...handleProfile, open: false })
                history.push("/dashboard/editprofile")
              }}>{(userData && userData.emailID === emailID) ? "Edit My Profile" : "Edit Profile"}</p>
              : null
            }
          </article>
        </section>

        <section className='userprofile--content'>
          <p>Status: <span><Active fill={isActive === "true" ? "#27AE60" : "#ec5d6d"} /> {isActive === "true" ? "Active" : "InActive"}</span></p>
          {emailID ? <p>User email: <span className='text-lowercase'>{emailID || "--"}</span></p> : null}
          {doj ? <p>Joined On: <span>{moment(doj).format("MMM DD, YYYY")}</span></p> : null}
          {empID ? <p>Employee ID: <span className='empID'>{empID || "--"}</span></p> : null}
          {responderID ? <p>Team Approver: <span style={{ textTransform: "lowercase" }}>{responderID}</span></p> : null}
          {empContact ? <p>Contact: <span>{empContact}</span></p> : null}
          {empAddress ? <p>Address: <span style={{ textTransform: "lowercase" }}>{empAddress}</span></p> : null}
          <hr />
          <p>Requests Made: <span className='empID'>{!loader ? Items.length.toLocaleString('en-US', { minimumIntegerDigits: 2 }) : <SkeletonLoad />}</span></p>
          <p>Pending Tickets: <span className='empID'>{!loader ? getPendingData.length.toLocaleString('en-US', { minimumIntegerDigits: 2 }) : <SkeletonLoad />}</span></p>
          <hr />
          {/* <p className='operation--reassign'> <ReassignProfileSVG /> Reassign Requests</p>
          <p onClick={() => setDeleteProfileModal({ open: true, data: empName })} className='operation--delete'> <DeleteProfileSVG /> Delete Account</p>
          <p></p> */}
        </section>
      </div>
    </article>
  </section>
}

export default UserProfile;