/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import { SkeletonLoader } from '../../../Component/Spinners/Skeleton';
import { GlobalContext } from '../../../Context';
import ApproveRequestModal from '../../../Component/Modals/ApproveRejectModal';
import Paginate from '../../../Component/Paginate';
import moment from "moment";
import { FeatureSubContext } from '../FeatureContext';
import { Accepted, Rejected, UtilsEmpty } from '../../../Component/DashboardUtils';
import { RequestUtils, FilterAction } from './RequestUtils';
import { useHistory } from 'react-router-dom';


const ManageRequests = () => {
  document.title = "REQO | Manage Requests";
  const { modalUtils, setModalUtils, handleProfile } = useContext(GlobalContext);
  let { getManageRequests, manageRequest, setRequestView } = useContext(FeatureSubContext);
  let [currentItems, setCurrentItems] = useState([{}]);
  const [filterData, setFilterData] = useState([]);
  const [handleView, setHandleView] = useState(false);
  localStorage.setItem("manageTotalRequest", manageRequest.length);
  const history = useHistory()


  useEffect(() => {
    getManageRequests();
    setModalUtils({ approveRejectModal: false });
  }, [])


  return <main>
    {modalUtils.approveRejectModal ? <ApproveRequestModal /> : null}
    <section className={`view--items ${(modalUtils.approveRejectModal) ? "opacity--reduce" : null}`}>

      <FilterAction setFilterData={setFilterData}
        manageRequest={manageRequest}
        setModalUtils={setModalUtils}
        modalUtils={modalUtils}
      />

      {(manageRequest && manageRequest.length >= 1) ? <>
        <article className='request--viewtable'>
          <table className='table'>
            <tbody className='viewtable--body'>
              {currentItems.map((items, i) => {
                return <React.Fragment key={i}>
                  <tr className='request--data' onClick={() => {
                    setRequestView(items);
                    !handleView ? history.push('/dashboard/viewmyrequest') : null
                  }}>
                    <td> {items.ticketNo ? <> &nbsp;&nbsp;
                      {items.isDefaultRequest ? <span className='default--request'>Default</span> : null}
                      #{items.ticketNo} </> : <SkeletonLoader />}
                    </td>
                    <td style={{ width: "280px" }}>
                      {items.requestTitle ? (items.requestTitle.length >= 29)
                        ? items.requestTitle.substring(0, 30) + "..." : items.requestTitle
                        : <SkeletonLoader />
                      }
                    </td>
                    <td>
                      {items.status ? items.status && items.status.includes("approved")
                        ? <span className='take--action accepted--action'><Accepted /> Accepted</span>
                        : items.status && items.status.includes("rejected")
                          ? <span className='take--action rejected--action'><Rejected /> Rejected</span>
                          : <span style={{ cursor: "pointer" }} className='take--action'>Take Action</span>
                        : <SkeletonLoader />}
                    </td>
                    <td className='request--user'>{items.requester ? <> {items.requester.split(" ")[0]}
                      <p className={`${(i % 2) ? "user--icon1" : (i % 3) ? "user--icon2" : "user--icon3"}`}>
                        {items.requester.substring(0, 1)}
                      </p>
                    </> : <SkeletonLoader />}
                    </td>
                    {!handleProfile.open ? <>
                      <td>{items.requestCreatedTime ? moment(items.requestCreatedTime).format("MMM DD, YYYY") : <SkeletonLoader />}</td>
                      <td className='request--options'>{items.ticketNo
                        ? <RequestUtils
                          getManageRequests={getManageRequests}
                          items={items}
                          setHandleView={setHandleView} /> : <SkeletonLoader />}
                      </td>
                    </> : null}
                  </tr>
                  <tr className='request--line'><td></td></tr>
                </React.Fragment>
              })}
            </tbody>
          </table>
        </article>
        <article className='view--pagination'>
          {manageRequest.length > 0 ? <Paginate data={filterData.length > 0 ? filterData : manageRequest}
            setCurrentItems={setCurrentItems} displayItem={10} /> : null}
        </article>
      </> : <section className='no--request'>
        <UtilsEmpty />
        <p>No manage request to browse!</p>
      </section>
      }
    </section>
  </main >
}

export default ManageRequests;