import React, { useState, useEffect, useContext } from 'react'
import { MultiSelect } from "react-multi-select-component";
import { FeatureSubContext } from '../../Pages/Manage/FeatureContext';
import Loader from "../Spinners/Loader";

const EmployeeDefaultRequest = ({ selected, setSelected, user }) => {
  let { getAllCategoryItem, data } = useContext(FeatureSubContext);
  let [check, setCheck] = useState(false);
  const [loader, setLoader] = useState(true);
  const { empRole, emailID } = (user && user.data) || {};


  useEffect(() => {
    getAllCategoryItem().then(() => {
      setLoader(false);
    });
  }, []);

  // console.log(data);

  const getSuperAdminMail = empRole.includes("super") ? emailID : null;
  const filterData = [];
  data && data.map((res) => {
    let { defaultItems, checked, isDefaultItemSelect, categoryAdmin } = res;
    if ((checked === "true") && isDefaultItemSelect === "true")
      return defaultItems && defaultItems.filter((item) => filterData.push({ item, categoryAdmin }))
    return null;
  });
  const options = filterData.map((values) => {
    const { itemName } = values.item;
    const categoryAdmin = values.categoryAdmin;
    return {
      label: itemName, value: itemName,
      categoryAdmins: getSuperAdminMail ? [...categoryAdmin, getSuperAdminMail] : categoryAdmin
    }
  });


  // operations......
  const chooseFn = (values) => {
    setCheck(true);
    setSelected(values);
  };
  useEffect(() => {
    if (!check && (data && data.length > 0)) {
      setSelected(options)
    }
  }, [check, data]);
  const toogleMenu = (val) => {
    if (val === "true") {
      document.getElementById('defaultItems--dropdown').scrollIntoView({ behavior: 'smooth' });
    };
  };


  return <>
    <p className='text'>Default Items that can be Requested</p>
    {
      filterData.length > 0 ? <section id='defaultItems--dropdown' className='input--multi'>
        <MultiSelect
          options={options}
          value={check ? selected : options}
          onChange={(values) => chooseFn(values)}
          labelledBy="Choose Category"
          onMenuToggle={() => toogleMenu("true")}
        />
      </section> : loader ? <Loader /> : <span>- No Items selected as default!</span>
    }
  </>
}

export default EmployeeDefaultRequest;