import React, { useContext } from 'react'
import { GlobalContext } from '../../../Context';
import "../../Style/profile_delete.css";


const DeleteProfileModal = () => {
  const { deleteProfileModal, setDeleteProfileModal, setCloseModal, closeModal } = useContext(GlobalContext);


  const handleClose = () => {
    setTimeout(() => {
      setCloseModal({ sentRequest: false });
      setDeleteProfileModal({ open: false });
    }, (450));
    setCloseModal({ sentRequest: true });
  };


  return <section className={`profile--delete animate__animated animate__bounceIn
  ${closeModal.sentRequest && "animate__bounceOut"}`}>
    <div className='card'>
      <span className='delete_profile--close' onClick={() => handleClose()}>
        <svg width="15" height="15" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L19.5 19.5" stroke="black" strokeWidth="2" strokeLinecap="round" />
          <path d="M1 19.5L19.5 0.999999" stroke="black" strokeWidth="2" strokeLinecap="round" />
        </svg>
      </span>
      <p>Confirm deletion of <span className='text-capitalize'>{deleteProfileModal.data}'s</span>  account? <br />
        This action cannot be undone.</p>
      <button className='btn'>
        Yes, I'm sure
      </button>
    </div>
  </section>
}

export default DeleteProfileModal;